import React from "react"
import loadable from "@loadable/component"
import ComingDesk from "../images/coming-soon-desktop.png"
import ComingMob from "../images/coming-soon-mobile.png"
import { Link } from "gatsby"
const ParticlesBg = loadable(() => import("particles-bg"))

const ComingData = () => {
  return (
    <div className="container-fluid pad0">
      <img
        src={ComingDesk}
        className="coming-desk d-none d-md-block"
        alt="comingsoon"
      />
      <img
        src={ComingMob}
        className="coming-desk  d-md-none d-xs-block"
        alt="comingsoon"
      />
      <ParticlesBg color="#7f7e7e" type="cobweb" num={30} bg={true} />
      <footer className="footer_1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 text-center">
              <div className="copyright">
                © copyright {new Date().getFullYear()} by scoreblox.com, a
                Kawntr Limited venture. All rights reserved. <br />
                <Link className="foot-link" to="/terms">
                  Terms and Conditions
                </Link>{" "}
                |{" "}
                <Link className="foot-link" to="/privacy-policy">
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default ComingData
