import React from "react"
import Layout from "@/components/layout"
import ComingData from "@/components/coming-soon-content"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
// import HeaderOne from "@/components/header-one"
// import Footer from "@/components/footer-one"

const ComingSoon = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Contact">
          <ComingData />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default ComingSoon
